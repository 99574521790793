import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class ScanComponent implements OnInit {

  scanResult: any = '';

  constructor() { }

  ngOnInit(): void {
  }

  onCodeResult(result: string){
    this.scanResult = result;

    if(this.scanResult.length == 28) {
      console.log("Usuário encontrado");
    }
  }

}
