import { Component, OnInit } from '@angular/core';

import { Atividade } from 'src/app/models/atividade.model';
import { AtividadesService } from 'src/app/services/atividades.service';

@Component({
  selector: 'app-atividades-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  list$ = this._service.atividades$;

  constructor(private _service: AtividadesService) { }

  ngOnInit(): void {
  }

  onDelete(id: string): void {
    console.log("Just message: deleted!", id);
  }

  onEdit(item: Atividade): void {
    console.log("Just message: editado!", item);
  }

}
