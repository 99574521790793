import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { AngularFirestore } from '@angular/fire/firestore';
import { Atividade } from '../models/atividade.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AtividadesService {

  atividades$: Observable<Atividade[]>;
  atividade: Atividade = new Atividade();

  constructor(private _afs: AngularFirestore) {
    this.getAll();
  }

  get(id: string) {
    this._afs
      .collection('atividades')
      .doc(id)
      .valueChanges().subscribe()
  }

  getAll() {
    this.atividades$ = this._afs
      .collection('atividades')
      .snapshotChanges()
      .pipe(
        map(value => value.map(x => {
          return {
            id: x.payload.doc.id,
            ...x.payload.doc.data() as Atividade
          } as Atividade;
        }))
      ) as Observable<Atividade[]>;
  }

  create(atividade: Atividade) {
    return new Promise<any>((resolve, reject) =>{
      this._afs
        .collection('atividades')
        .add(atividade)
        .then(res => {
          console.log(res)
        }, err => reject(err));
    });
  }

  delete(atividade: Atividade) {
    return  new Promise<any>((resolve, reject) =>{
      this._afs
        .collection("atividades")
        .doc(atividade.id)
        .delete()
        .then(res => {}, err => reject(err));
    });
  }

  update(atividade: Atividade) {
    return this._afs
      .collection("atividades")
      .doc(atividade.id)
      .update({
        title: atividade.title
      });
  }


/*

  getAll() {
    return this.fs.collection('atividades').snapshotChanges(a);
  }

  onSave(atividade: Atividade): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        atividade.id = atividade.id || this.fs.createId(a);
        let result = await this.atividadesCollection.doc(atividade.id)
                                                    .set(atividade)
                                                    .then(res=>{}, err => reject(err));
        resolve(result);
      } catch (error: any) {
        reject(error.message);
      }
    });
  }

  onDelete(id: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await this.atividadesCollection.doc(id).delete();
        resolve(result);
      } catch (error: any) {
        reject(error.message);
      }
    });
  }

  */
}
