import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fiscalizar',
  templateUrl: './fiscalizar.component.html',
  styleUrls: ['./fiscalizar.component.css']
})
export class FiscalizarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
