import { Component, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { FiscalizarService } from 'src/app/services/fiscalizar.service';

@Component({
  selector: 'app-fiscal-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class ScanComponent implements OnInit {
  @ViewChild(ZXingScannerComponent) scanner: ZXingScannerComponent;

  scanResult: string;
  enabledScan: boolean = true;

  constructor(public service: FiscalizarService) { }

  ngOnInit(): void {
  }


  //#region SCANNER

  onCodeResult(result: string){
    if(this.validateResult(result)){
      this.scanner.enable = false;
      this.scanResult = result;
      this.service.findUnidade(result).subscribe(
        (unidade) => {
          if(unidade) {
            console.log("Unidade " + unidade.nome + " encontrada!");
            this.enabledScan = false;
            this.service.unidade = unidade;
          } else {
            console.warn("Unidade com id " + result + " não encontrada");
            this.enableScanner();
          }
        }
      );
    }
  }

  validateResult(result: string): boolean {
    if(!result){
      console.log("Empty code");
      return false;
    }

    if(result.length != 20) {
      console.log("Invalid code length: ", result);
      return false;
    }

    console.log("Result validated: ", result);
    return true;
  }

  enableScanner(): void {
    this.service.unidade = null;
    this.enabledScan = true;
    this.scanner.enable = true;
    this.scanResult = "";
  }

  //#endregion


}
