import { FiscalGuard } from './guards/fiscal.guard';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

// Firebase
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

// Application
import { MenuComponent } from './shared/menu/menu.component';
import { PresencasComponent } from './pages/presencas/presencas.component';
import { UnidadesComponent } from './pages/unidades/unidades.component';
import { AtividadesComponent } from './pages/atividades/atividades.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { ScanComponent } from './pages/scan/scan.component';
import { LoginComponent } from './pages/login/login.component';
import { FormComponent } from './pages/atividades/form/form.component';
import { ListComponent } from './pages/atividades/list/list.component';
import { AuthService } from './shared/auth/auth.service';
import { ScanLoginComponent } from './pages/login/scan/scan-login.component';
import { AvaliarComponent } from './pages/fiscalizar/avaliar/avaliar.component';
import { FiscalizarComponent } from './pages/fiscalizar/fiscalizar.component';
import { ScanComponent as FiscalScanComponent } from './pages/fiscalizar/scan/scan.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    PresencasComponent,
    UnidadesComponent,
    AtividadesComponent,
    RankingComponent,
    ScanComponent,
    LoginComponent,
    FormComponent,
    ListComponent,
    ScanLoginComponent,
    AvaliarComponent,
    FiscalizarComponent,
    FiscalScanComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    FormsModule,
    ZXingScannerModule,
  ],
  providers: [AuthService, AuthGuard, FiscalGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
