import { FiscalService } from '../auth/fiscal.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(public authService: AuthService,
              private _fiscalService: FiscalService) { }

  ngOnInit(): void {
  }

  onLogout(): void {
    this._fiscalService.LogOutWithoutRedirect();
    this.authService.SignOut();
  }

}
