import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { Avaliacao } from '../models/avaliacao';

@Injectable({
  providedIn: 'root'
})
export class RankingService {

  avaliacoes$: Observable<Avaliacao[]>;

  constructor(private _afs: AngularFirestore) {
    this.getAll();
  }

  getAll() {
    this.avaliacoes$ = this._afs
      .collection('avaliacoes')
      .snapshotChanges()
      .pipe(
        map(value => value.map(x => {
          return {
            id: x.payload.doc.id,
            ...x.payload.doc.data() as Avaliacao
          } as Avaliacao;
        }))
      ) as Observable<Avaliacao[]>;
  }
}
