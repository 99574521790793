<div class="row" [hidden]="!this.service.unidade">
  <p>Clique para aplicar os pontos</p>
  <div class="col-md-4 col-sm-4 col-12">
    <button type="button" class="btn btn-lg btn-outline-danger" (click)="rate25()"><i class="fas fa-thumbs-down"></i> 25 pontos</button>
  </div>
  <div class="col-md-4 col-sm-4 col-12">
    <button type="button" class="btn btn-lg btn-outline-warning" (click)="rate50()"><i class="fas fa-star-half-alt"></i> 50 pontos</button>
  </div>
  <div class="col-md-4 col-sm-4 col-12">
    <button type="button" class="btn btn-lg btn-outline-success" (click)="rate100()"><i class="fas fa-thumbs-up"></i> 100 pontos</button>
  </div>
</div>
