import { FiscalGuard } from './guards/fiscal.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import { AtividadesComponent } from './pages/atividades/atividades.component';
import { LoginComponent } from './pages/login/login.component';
import { ScanLoginComponent } from './pages/login/scan/scan-login.component';
import { PresencasComponent } from './pages/presencas/presencas.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { UnidadesComponent } from './pages/unidades/unidades.component';
import { FiscalizarComponent } from './pages/fiscalizar/fiscalizar.component';

const routes: Routes = [
  { path: '', redirectTo: 'atividades', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'login/scan', component: ScanLoginComponent },

  { path: 'fiscalizar', component: FiscalizarComponent, canActivate: [FiscalGuard] },

  { path: 'presencas', component: PresencasComponent, canActivate: [AuthGuard] },
  { path: 'unidades', component: UnidadesComponent, canActivate: [AuthGuard] },
  { path: 'atividades', component: AtividadesComponent, canActivate: [AuthGuard] },
  { path: 'ranking', component: RankingComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
