import { UnidadesService } from './unidades.service';
import { FiscalService } from './../shared/auth/fiscal.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Unidade } from '../interfaces/unidade';
import { Avaliacao } from '../models/avaliacao';

const COLLECTION_KEY : string = 'avaliacoes';

@Injectable({
  providedIn: 'root'
})
export class FiscalizarService {
  unidade: Unidade;
  avaliacao: Avaliacao = new Avaliacao();

  constructor(
    private _afs: AngularFirestore,
    private _fiscalService: FiscalService,
    private _unidadesService: UnidadesService
  ) { }

  async avaliar() : Promise<void> {
    return new Promise<void>(async (resolve,reject) => {

      this.avaliacao.atividade = this._fiscalService.fiscal.atividade;
      this.avaliacao.timestamp = Date.now();
      this.avaliacao.unidade = this.unidade.id;
      this.avaliacao.fiscal = this._fiscalService.fiscal.id;

      //let isAvaliado: boolean = await this.isAvaliado(this.avaliacao.unidade, this.avaliacao.atividade).toPromise();
      let isAvaliado: boolean = await this.isAvaliado(this.avaliacao.unidade, this.avaliacao.atividade);
      console.log("IsAvaliado: ", isAvaliado);

      if(isAvaliado){
        let msg = "Unidade " + this.unidade.nome + " já foi avaliada na atividade " + this._fiscalService.fiscal.atividade;
        console.log(msg);
        reject(msg);
      }else{
        console.log("Unidade " + this.unidade.nome + " ainda não avaliada!");
        this.avaliacao.id = this._afs.createId();
        resolve(await this.saveAvaliacao(this.avaliacao));
      }
    });
  }

  isAvaliado(unidade: string, atividade: string) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._afs
        .collection(COLLECTION_KEY, ref => ref.where('unidade','==',unidade)
                                              .where('atividade','==',atividade))
        .valueChanges()
        .subscribe(data => {
          if(data.length > 0){
            resolve(true);
          }else{
            resolve(false);
          }
        });
    });
  }

  saveAvaliacao(avaliacao: Avaliacao) : Promise<void> {
    let obj = Object.assign({}, avaliacao);
    //obj.timestamp = Date.parse(obj.timestamp.toString());
    return this._afs.collection(COLLECTION_KEY)
                    .doc(avaliacao.id)
                    .set(obj, { merge: true });
  }

  getFiscal(){
    return this._fiscalService.fiscal
  }

  getAtividadeDoFiscal(){
    return this._fiscalService.fiscal.atividade
  }

  checkUnidade() : boolean {
    if(this.unidade){
      return true;
    }
    return false;
  }

  findUnidade(id: string) : Observable<Unidade>  {
    return this._unidadesService.get(id);
  }



}
