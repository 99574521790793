import { FiscalService } from '../../shared/auth/fiscal.service';
import { AuthService } from './../../shared/auth/auth.service';
import {Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit  {

  form: any = {};
  errorMessage = '';

  email: string = "";
  password: string = "";

  constructor(private _router: Router,
              public authService: AuthService,
              private _fiscalService: FiscalService) { }

  ngOnInit(): void {
    if(this._fiscalService.isFiscalLoggedIn){
      this._router.navigate(['fiscalizar']);
    }
  }

  onSubmit(): void {
    let spin = document.getElementById('spin-card');
    spin!.classList.remove("none");

    let text = document.getElementById('submit-button-text');
    text!.classList.add("none");

    this.authService.SignIn(this.email, this.password);
  }

  onScanCode(): void {
    this._router.navigate(['login/scan']);
  }
}
