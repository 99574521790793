import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Unidade } from '../interfaces/unidade';

const COLLECTION_KEY : string = 'unidades';

@Injectable({
  providedIn: 'root'
})
export class UnidadesService {

  unidades$: Observable<Unidade[]>;

  constructor(private _afs: AngularFirestore) {
    this.unidades$ = this.getAll();
  }

  getAll() : Observable<Unidade[]> {
    return this._afs
      .collection(COLLECTION_KEY)
      .snapshotChanges()
      .pipe(
        map(value => value.map(x => {
          return {
            id: x.payload.doc.id,
            ...x.payload.doc.data() as Unidade
          } as Unidade;
        }))
      ) as Observable<Unidade[]>;
  }

  public get(id: string) : Observable<Unidade> {
    return this._afs
      .collection(COLLECTION_KEY)
      .doc(id)
      .snapshotChanges()
      .pipe(
        map(x => {
          if(x.payload.exists){
            let ret = {
              id: x.payload.id,
              ...x.payload.data() as Unidade
            } as Unidade;
            return ret;
          }else{
            return null;
          }
        })
      ) as Observable<Unidade>;
  }

  public exists(id: string) : Observable<boolean> {
    return this._afs
      .collection(COLLECTION_KEY)
      .doc(id)
      .snapshotChanges()
      .pipe(
        map(x => x.payload.exists)
      ) as Observable<boolean>;
  }
}
