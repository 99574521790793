import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { RankingService } from './../../services/ranking.service';
import { UnidadesService } from './../../services/unidades.service';
import { Avaliacao } from 'src/app/models/avaliacao';
import { Unidade } from 'src/app/interfaces/unidade';
import { Ranking } from 'src/app/models/ranking.model';

@Component({
  selector: 'app-rankings',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingComponent implements OnInit {

  avaliacoes$: Observable<Avaliacao[]> = this._service.avaliacoes$;
  unidades$: Observable<Unidade[]> = this._unidadesService.unidades$;
  ranking: Ranking[] = [];

  constructor(private _service: RankingService,
              private _unidadesService: UnidadesService) { }

  ngOnInit(): void {
    this._service.getAll();
  }

  calcRankingPoints(){
    let unidades = this._unidadesService.unidades$;
    let ranking
  }

}
