<div class="container-fluid m-5">
  <div class="row">
    <div class="col-md-8">
      <app-atividades-list></app-atividades-list>
    </div>
      <div class="col-md-4">
          <app-atividades-form></app-atividades-form>
      </div>
  </div>
</div>
