import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Fiscal } from 'src/app/interfaces/fiscal';

const COLLECTION_KEY : string = 'fiscais';
const STORAGE_KEY : string = 'fiscal';

@Injectable({
  providedIn: 'root'
})
export class FiscalService {
  fiscalData: Fiscal; // Save logged in fiscal data
  fiscal: Fiscal;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    this.fiscal = this.getCredential();
  }

  //#region VALIDATIONS

  get isFiscalLoggedIn(): boolean {
    let fiscal = this.getCredential();
    return (fiscal !== null) ? true : false;
  }

  //#endregion

  //#region AUTH

  public LogIn(id: string) {
    console.log("AuthFiscaisService: LogIn");

    this.getFiscal(id).subscribe((fiscal) => {
      this.fiscal = fiscal;
      console.log("Fiscal: ", fiscal);
      if(fiscal){
        this.storeCredential(fiscal);
        console.log(fiscal.nome + " fez login com sucesso!");
        this.ngZone.run(() => {
          this.router.navigate(['fiscalizar']);
        });
      }else{
        console.warn("Login inválido");
        this.router.navigate(['login']);
      }
    });
  }

  public LogOut() {
    this.LogOutWithoutRedirect();
    this.router.navigate(['login']);
  }

  public LogOutWithoutRedirect() {
    this.deleteCredential();
    console.log("AuthFiscaisService: LogOut");
  }

  //#endregion

  //#region FIREBASE

  private getFiscal(id: string) : Observable<Fiscal> {
    return this.afs.collection(COLLECTION_KEY)
                  .doc(id)
                  .snapshotChanges()
                  .pipe(
                    map(x => {
                      if(x.payload.exists){
                        console.log("Payload: ", x.payload);
                        let ret = {
                          id: x.payload.id,
                          ...x.payload.data() as Fiscal
                        } as Fiscal;
                        console.log("Return: ", ret);
                        return ret;
                      }else{
                        return null;
                      }
                    })
                  ) as Observable<Fiscal>;
  }

  //#endregion

  //#region CREDENTIALS

  private storeCredential(fiscal: Fiscal): void {
    this.fiscalData = fiscal;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.fiscalData));
    JSON.parse(localStorage.getItem(STORAGE_KEY));
  }

  private deleteCredential(): void {
    localStorage.removeItem(STORAGE_KEY);
  }

  private getCredential(): Fiscal {
    return JSON.parse(localStorage.getItem(STORAGE_KEY));
  }

  //#endregion
}
