<div class="login-page justify-content-center">

  <h3>Login</h3>

  <div class="login-box">

    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Faça o login para fiscalizar uma atividade</p>

        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
          <div class="input-group mb-3">
            <input type="email" name="email" [(ngModel)]="form.email" required #email="ngModel" placeholder="Email" [ngClass]="{'form-control':true,'is-valid':email.touched && email.valid, 'is-invalid': email.touched && email.invalid}" required>
            <div class="input-group-append"><div class="input-group-text">
                <span [ngClass]="{'fas fa-envelope':true, 'text-success':email.touched && email.valid, 'text-danger': email.touched && email.invalid}"></span>
            </div></div>
            <span id="email-required" class="error invalid-feedback" *ngIf="email.errors?.required" >Campo obrigatório!</span>
          </div>
          <div class="input-group mb-3">
              <input type="password" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" placeholder="Senha" [ngClass]="{'form-control':true,'is-valid':password.touched && password.valid, 'is-invalid': password.touched && password.invalid}" required>
              <div class="input-group-append"><div class="input-group-text"><span [ngClass]="{'fas fa-lock':true, 'text-success':password.touched && password.valid, 'text-danger': password.touched && password.invalid}"></span></div></div>
              <span id="password-required" class="error invalid-feedback" *ngIf="password.errors?.required">Campo obrigatório!</span>
              <span id="password-minlength" class="error invalid-feedback" *ngIf="password.errors?.minlength">Mínimo de 6 caracteres</span>
          </div>
          <div class="row">
            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-lg btn-block" (click)="onSubmit()" >
                <div class="overlay" id="overlay-card">
                  <span id="submit-button-text">Entrar</span>
                  <i id="spin-card" class="fas fa-sync-alt fa-spin none"></i>
                  <!-- <i id="spin-card" class="fas fa-2x fa-sync-alt fa-spin none"></i> -->
                </div>
              </button>
            </div>
          </div>
          <div class="row">
            <span class="or"><span class="orInner">Ou</span></span>
            <div class="col">
              <button type="button" class="btn btn-outline-primary btn-lg btn-block" (click)="onScanCode()">
                <i class="fas fa-qrcode"></i> Entrar usando QRCode
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
  <!-- /.login-box -->
</div>
