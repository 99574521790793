import { FiscalService } from '../../../shared/auth/fiscal.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scan-login',
  templateUrl: './scan-login.component.html',
  styleUrls: ['./scan-login.component.css']
})
export class ScanLoginComponent implements OnInit {

  scanResult: any = '';

  constructor(private _service: FiscalService) { }

  ngOnInit(): void {
  }

  onCodeResult(result: string){
    if(this.validateResult(result)){
      this.scanResult = result;
      this._service.LogIn(result);
    }
  }

  validateResult(result: string): boolean {
    if(!result){
      console.info("Empty code");
      return false;
    }

    if(result.length != 20) {
      console.info("Invalid code length: ", result);
      return false;
    }

    return true;
  }


}
