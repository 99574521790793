<div class="row" id="zxing">
  <zxing-scanner [hidden]="!this.enabledScan" (scanSuccess)="onCodeResult($event)"></zxing-scanner>
</div>
<div class="row">
  <p>Código lido: <strong>"{{ scanResult }}"</strong></p>
  <div class="col-md-12">
    <div class="card" [hidden]="!this.service.unidade">
      <h5 class="card-header">Unidade: {{ this.service.unidade?.nome }}</h5>
      <div class="card-body">
        <p class="card-text">Idade: {{ this.service.unidade?.idade }}</p>
        <a (click)="enableScanner()" class="btn btn-danger"><i class="fas fa-qrcode"></i> Ler outro código</a>
      </div>
    </div>
  </div>
</div>
