<p>form works!</p>
<div class="col-md-6">
  <div class="form-group">
      <label for="id">Id: </label>
      <input type="text" name="id" id="id" class="form-control" [(ngModel)]="atividade.id">
      <label for="title">Título: </label>
      <input type="text" name="title" id="title" class="form-control" [(ngModel)]="atividade.title">
      <button class="btn btn-primary" (click)="onSubmit()">Salvar</button>
      <button class="waves-effect waves-light btn col s12" (click)="onSubmit()">Submit</button>
  </div>
</div>
<div>
  <h4>Dados criados com sucesso!</h4>
  <button class="btn btn-light" (click)="initForm()">Novo</button>
</div>
{{ atividade | json }}
