<table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Id</th>
      <th scope="col">Title</th>
      <th scope="col">Clube</th>
      <th scope="col">Action </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of list$ | async; let i = index">
      <th scope="row">{{ i + 1 }}</th>
      <td>{{ item.id }}</td>
      <td>{{ item.title }}</td>
      <td>{{ item.clube }}</td>
      <td>
        <span (click)="onEdit(item)" class="btn btn-primary">Editar</span> &nbsp;
        <span (click)="onDelete(item.id!)" class="btn btn-danger">Apagar</span> &nbsp;
        <!--<span routerLink="/update-item/{{item.id}}" class="text-primary">Edit</span>-->
      </td>
    </tr>
  </tbody>
</table>

{{ list$ | async | json }}
