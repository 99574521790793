import { FiscalizarService } from 'src/app/services/fiscalizar.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-avaliar',
  templateUrl: './avaliar.component.html',
  styleUrls: ['./avaliar.component.css']
})
export class AvaliarComponent implements OnInit {

  constructor(private _router: Router,
              public service: FiscalizarService) { }

  ngOnInit(): void {
  }


  rate25(): void {
    Swal.fire({
      title: 'Atenção!',
      html: 'Deseja dar a nota 25?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!'
    }).then((result) => {
      if (result.value) {
        this.service.avaliacao.pontos = 25;
        this.sendReview();
      }
    });
  }

  rate50(): void {
    Swal.fire({
      title: 'Atenção!',
      html: 'Deseja dar a nota 50?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!'
    }).then((result) => {
      if (result.value) {
        this.service.avaliacao.pontos = 50;
        this.sendReview();
      }
    });
  }

  rate100(): void {
    Swal.fire({
      title: 'Atenção!',
      html: 'Deseja dar a nota 100?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!'
    }).then((result) => {
      if (result.value) {
        this.service.avaliacao.pontos = 100;
        this.sendReview();
      }
    });
  }

  sendReview(): void {
    this.service.avaliar().then(
      dados => {
        Swal.fire({
          toast: false,
          width: '20rem',
          position: 'top-end',
          icon: 'success',
          title: 'Resposta avaliada com sucesso!',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        }).then(() => {
          delay(2000);
          window.location.reload();
        });
      },(error) => {
        Swal.fire({
          toast: true,
          width: '20rem',
          position: 'top-end',
          icon: 'error',
          title: 'Erro ao avaliar!',
          text: error,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        }).then(() => {
          delay(2000);
          window.location.reload();
        });
      }).catch(error => {
        this.returnError(error);
        delay(2000);
        window.location.reload();
      }
    );
  }

  returnError(error: any): void {
    console.error('Não foi possível atender a sua solicitação.');
    console.error('Error: ' + error);

    if(error) {
      if(error.errors){
        error.errors.forEach((e: any) => {
          console.error(e);
        });
      }else{
        Swal.fire({
          toast: false,
          width: '20rem',
          position: 'top-end',
          icon: 'error',
          title: 'Erro',
          text: error,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    };
  }

}
