import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Atividade } from 'src/app/models/atividade.model';
import { AtividadesService } from 'src/app/services/atividades.service';

@Component({
  selector: 'app-atividades-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  atividade: Atividade = this._service.atividade;
  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    title: new FormControl('')
  });

  constructor(private _router: Router,
              private _fb: FormBuilder,
              private _service: AtividadesService) {
    this.initForm();
  }

  ngOnInit(): void {
    if(typeof this.atividade == 'undefined'){

    }else{
      this.form.patchValue(this.atividade);
    }
  }

  initForm(): void {
    this.form = this._fb.group({
      id: ['',],
      title: ['',[Validators.required]],
    })
  }

  onSubmit(): void {
    console.log("Atividade " + this.atividade.title + "salva com sucesso!", this.form.value);
    if(this.form.valid){
      let atv = this.form.value;
      this._service.update(atv)
                  .then(res => {
                    //Do something
                  });
      this.form.reset();
    }
  }

}
